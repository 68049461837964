<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'

const emit = defineEmits<{
  'update:modelValue': [modelValue: boolean]
}>()
</script>

<template>
  <VueFinalModal
    content-class="sidebar-modal scroll-smooth absolute overflow-y-auto bottom-0 right-0 top-0 h-full w-full px-6 pb-6 bg-white dark:bg-gray-800 md:w-1/3"
    overlay-transition="vfm-fade"
    :content-transition="{
      enterActiveClass: 'transition duration-200 ease-out',
      enterFromClass: 'translate-x-[500px] opacity-0',
      enterToClass: 'translate-x-0 opacity-100',
      leaveActiveClass: 'transition duration-150 ease-in',
      leaveFromClass: 'translate-x-0 opacity-100',
      leaveToClass: 'translate-x-[500px] opacity-0',
    }"
    @update:model-value="(val) => emit('update:modelValue', val)"
  >
    <slot />
  </VueFinalModal>
</template>
